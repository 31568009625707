import { Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";
import GoPaymentCallback from "./callbacks/GoPaymentCallback";
import ApiPaymentCallback from "./callbacks/ApiPaymentCallback";
import UnitasCallback from "./callbacks/UnitasCallback";
import AccountInfoCallback from "./callbacks/AccountInfoCallback";
import PisLinkCallback from "./callbacks/PisLinkCallback";
import PisCallback from "./callbacks/PisCallback";

function App() {
  return (
    <Routes>
      <Route path="/a2apay-go" element={<GoPaymentCallback />} />
      <Route path="/api-callback" element={<ApiPaymentCallback />} />
      <Route path="/unitas" element={<UnitasCallback />} />
      <Route path="/verify-account" element={<AccountInfoCallback />} />
      <Route path="/link-callback" element={<PisLinkCallback />} />
      <Route path="/callback" element={<PisCallback />} />
    </Routes>
  );
}

export default App;
