import { useState, useEffect } from "react";
import { TiTick, TiTimes } from "react-icons/ti";
import logo from "../../assets/EchoPay.png";
import { Button, Spinner, Badge } from "reactstrap";

export const Header = () => {
  return (
    <div className="fixed-top p-2 bg-dark d-flex justify-content-center">
      <img className="footer_logo" src={logo} alt="EchoPay" />
    </div>
  );
};

export const Footer = ({ loading, onClick, status, receipt }) => {
  const [download, setDownload] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    if (status === "SUCCESS") setDownload(true);
    if (status !== "PENDING") setDisableButton(true);
  }, [status]);

  return (
    <div className="fixed-bottom p-3 bg-dark">
      {download ? (
        <Button color="primary" className="w-100" onClick={receipt}>
          Download Receipt
        </Button>
      ) : (
        <Button
          disabled={disableButton}
          color="success"
          className="w-100"
          onClick={onClick}
        >
          {loading ? (
            <>
              <Spinner size="sm">Loading</Spinner>
              <span>{"    "} Loading</span>
            </>
          ) : (
            "Make Payment"
          )}
        </Button>
      )}
    </div>
  );
};

export const FooterApi = ({ loading, onClick, status, redirect }) => {
  const [download, setDownload] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    if (status === "SUCCESS") setDownload(true);
    if (status !== "PENDING") setDisableButton(true);
  }, [status]);

  return (
    <div className="fixed-bottom p-3 bg-dark">
      {download ? (
        <Button color="primary" className="w-100" onClick={redirect}>
          Go back to what you were doing...
        </Button>
      ) : (
        <Button
          disabled={disableButton}
          color="success"
          className="w-100"
          onClick={onClick}
        >
          {loading ? (
            <>
              <Spinner size="sm">Loading</Spinner>
              <span>{"    "} Loading</span>
            </>
          ) : (
            "Make Payment"
          )}
        </Button>
      )}
    </div>
  );
};

export const TitleSimple = ({ status }) => {
  const [badgeColor, setBadgeColor] = useState(false);

  useEffect(() => {
    if (status === "SUCCESS") {
      setBadgeColor("success");
    } else if (status === "PENDING") {
      setBadgeColor("secondary");
    } else {
      setBadgeColor("danger");
    }
  }, [status]);

  return (
    <>
      <h5 className="pt-3">
        Payment Confirmation:{"  "}
        <Badge className="text-light" color={badgeColor}>
          {status}
        </Badge>
      </h5>
      <p>
        Please <strong>click the button</strong> below to process your payment.
        If you would like to cancel this payment please close the tab.
      </p>
    </>
  );
};

export const Title = ({ status, requestId }) => {
  const [badgeColor, setBadgeColor] = useState(false);

  useEffect(() => {
    if (status === "SUCCESS") {
      setBadgeColor("success");
    } else if (status === "PENDING") {
      setBadgeColor("secondary");
    } else {
      setBadgeColor("danger");
    }
  }, [status]);

  return (
    <>
      <h5 className="pt-3">
        Payment Confirmation:{"  "}
        <Badge className="text-light" color={badgeColor}>
          {status}
        </Badge>
      </h5>
      {status === "PENDING" ? (
        <p>
          Please use the button below to process your payment. If you would like
          to cancel the payment please close the tab.
        </p>
      ) : (
        <p>
          Your payment has been processed with the status {status}.{" "}
          {status === "SUCCESS"
            ? "You can now download a copy of this payment using the button below. We recommend closing this tab once have finished."
            : "If you have a questions regarding this payment please use reference: " +
              requestId}
        </p>
      )}
    </>
  );
};

export const AccountTitle = ({ status, requestId }) => {
  const [badgeColor, setBadgeColor] = useState(false);

  useEffect(() => {
    if (status === "SUCCESS") {
      setBadgeColor("success");
    } else if (status === "PENDING") {
      setBadgeColor("secondary");
    } else {
      setBadgeColor("danger");
    }
  }, [status]);

  return (
    <>
      <h5 className="pt-3">
        Account Confirmation:{"  "}
        <Badge className="text-light" color={badgeColor}>
          {status}
        </Badge>
      </h5>
      {status === "PENDING" ? (
        <p>
          Please use the button below to verify your account. If you would like
          to cancel please close this tab.
        </p>
      ) : (
        <p>
          The status of verifiying your account is: {status}. If you have any
          questions please use the reference: {requestId}
        </p>
      )}
    </>
  );
};

export const Tick = ({ status, text }) => {
  const [responseReceived, setResponseRevied] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (status !== "PENDING") setResponseRevied(true);
    setShow(true);
  }, [status]);

  if (!show) return;

  return (
    <div className="d-flex align-items-center">
      <div
        className={`rounded-circle bg-${
          responseReceived ? "success" : "danger"
        } circle my-2`}
        style={{
          transition: "all 0.5s ease",
          WebkitTransition: "all 0.5s ease",
          MozTransition: "all 0.5s ease",
        }}
      >
        {status !== "PENDING" ? <TiTick /> : <TiTimes />}
      </div>

      <p className="m-0 ms-3">{text}</p>
    </div>
  );
};

export const FooterNoReceipt = ({ loading, onClick, status }) => {
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    if (status !== "PENDING") setDisableButton(true);
  }, [status]);

  return (
    <div className="fixed-bottom p-3 bg-dark">
      <Button
        disabled={disableButton}
        color="success"
        className="w-100"
        onClick={onClick}
      >
        {loading ? (
          <>
            <Spinner size="sm">Loading</Spinner>
            <span>{"    "} Loading</span>
          </>
        ) : (
          "Verify Account"
        )}
      </Button>
    </div>
  );
};
