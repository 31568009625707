import { initializeApp } from "firebase/app";
import { getFunctions } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const firebaseConfig = {
  apiKey: "AIzaSyDaSA8dsGkW0A-jSwxFqHC3CMMNnUrsO74",
  authDomain: "a2apay.firebaseapp.com",
  databaseURL: "https://a2apay-default-rtdb.firebaseio.com",
  projectId: "a2apay",
  storageBucket: "a2apay.appspot.com",
  messagingSenderId: "89098769133",
  appId: "1:89098769133:web:ee22d9da64944eeb51b2e4",
};

export const app = initializeApp(firebaseConfig);
export const functions = getFunctions(app);
getAnalytics(app);

export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LcZDnskAAAAALFi11PbUXaTTXLWwqXR2gOAJjjN"),
  isTokenAutoRefreshEnabled: true,
});
