import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase";

export async function completePaymentCallback(
  requestId,
  tokenId,
  message,
  functionName
) {
  const cloudFunction = await httpsCallable(functions, functionName);
  try {
    const data = await cloudFunction({
      requestId,
      tokenId,
      message,
    });
    return [data.data, null];
  } catch (error) {
    console.error(error);
    return [null, error];
  }
}

export async function callback(requestId, tokenId, message, functionName) {
  const cloudFunction = await httpsCallable(functions, functionName);
  try {
    const data = await cloudFunction({
      requestId,
      tokenId,
      message,
    });
    return data.data;
  } catch (error) {
    console.error(error);
    return {
      status: error.code || "ERROR",
      message: error.message || "Sorry there has been an error.",
    };
  }
}
