import { useEffect } from "react";
import { Container, Spinner } from "reactstrap";
import { confetti, paramsSearch } from "./components/functions";
import { callback } from "../firebase/functions";

const PisLinkCallback = () => {
  useEffect(() => {
    async function makePayment() {
      const params = await paramsSearch();
      const data = await callback(
        params.requestId,
        params.tokenId,
        params.message,
        "pis-linkcallback"
      );

      const url = "https://a2apay.co.uk/status?";
      const { status, info } = data;
      const urlStatus = "status=" + status;
      const urlInfo = "&info=" + info;
      window.location.href = url + urlStatus + urlInfo;
    }

    makePayment();
  }, []);

  return (
    <Container className="d-flex flex-column align-items-center 100vh">
      <Spinner size="sm" />
    </Container>
  );
};

export default PisLinkCallback;
