import JSConfetti from "js-confetti";
import { jsPDF } from "jspdf";

export function confetti() {
  const jsConfetti = new JSConfetti();
  jsConfetti.addConfetti({
    confettiColors: [
      "#ff8d800",
      "#000000",
      "#ffd800",
      "#000000",
      "#ffd800",
      "#000000",
    ],
  });
}

export function paramsSearch() {
  const params = new URL(document.location).searchParams;
  const tokenId = params.get("tokenId");
  const requestId = params.get("request-id");
  const message = params.get("message");
  return { tokenId, requestId, message };
}

export function createNewPdf(ref, date, status, description, amount, merchant) {
  const doc = jsPDF();
  doc.setDrawColor(255, 216, 0);
  doc.setLineWidth(1.5);
  doc.line(20, 15, 190, 15);

  doc.setFont("helvetica", "normal", "bold");
  doc.setFontSize(16);
  doc.text(20, 30, "EchoPay Payment Confirmation");

  doc.setFont("helvetica", "normal", "bold");
  doc.setFontSize(13);
  doc.text(20, 42, "Summary");

  doc.setFont("helvetica", "normal");
  doc.setFontSize(12);

  doc.text(20, 50, "Payment ID:");
  doc.text(70, 50, ref);

  doc.text(20, 57, "Date of Payment:");
  doc.text(70, 57, date);

  doc.text(20, 64, "Payment Method:");
  doc.text(70, 64, "EchoPay - Pay by Bank");

  doc.text(20, 71, "Payment Status:");
  doc.text(70, 71, status);

  doc.setFont("helvetica", "normal", "bold");
  doc.setFontSize(13);
  doc.text(20, 90, "Payment Details");

  doc.setFont("helvetica", "normal");

  // Table
  doc.setDrawColor(0);
  doc.setLineWidth(0.1);
  doc.setFillColor(255, 255, 255);
  doc.roundedRect(20, 95, 168, 60, 1, 1, "FD");
  doc.line(20, 110, 188, 110);
  doc.line(20, 125, 188, 125);
  doc.line(20, 140, 188, 140);
  doc.line(105, 155, 105, 95); // vertical line

  // Table text
  doc.setFontSize(12);
  doc.text(25, 104, "Merchant Paid");
  doc.text(110, 104, merchant);

  doc.text(25, 119, "Payment Description");
  doc.text(110, 119, description);

  doc.text(25, 134, "Payment Amount");
  doc.text(110, 134, "£" + amount);

  doc.text(25, 149, "Payment Status");
  doc.text(110, 149, status);

  doc.setFontSize(8);
  doc.text(20, 162, [
    "Thank you for using EchoPay. If you have any questions regarding the above payment please contact your merchant or email us",
    "at support@echopay.co.uk. Please include the payment ID to help us with your enquiry.",
  ]);

  doc.setDrawColor(255, 216, 0);
  doc.setLineWidth(1.5);
  doc.line(20, 282, 190, 282);

  doc.save("a4.pdf");
}
