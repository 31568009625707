import { useState } from "react";
import { Container, Alert } from "reactstrap";
import { Header, FooterApi, Title, Tick } from "./components/Structure";
import { confetti, paramsSearch } from "./components/functions";
import { completePaymentCallback } from "../firebase/functions";
import "./styles.css";

const GoPaymentCallback = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("PENDING");
  const [info, setInfo] = useState("-");
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [requestId, setRequestId] = useState("");

  const toggleAlert = () => setShowAlert((prev) => !prev);

  async function makePayment() {
    setLoading(true);
    const params = await paramsSearch();
    setRequestId(params.requestId);
    console.log(params);
    const [data, error] = await completePaymentCallback(
      params.requestId,
      params.tokenId,
      params.message,
      "completeTokenRequestApi"
    );

    if (error) {
      setStatus("ERROR");
      setInfo(error.message);
      showAlert();
      setLoading(false);
      return;
    }

    setStatus(data.status);
    setInfo(data.info);
    setRedirectUrl(data.url);
    toggleAlert();
    if (data.status === "SUCCESS") confetti();
    setLoading(false);
  }

  return (
    <Container className="d-flex flex-column p-6 max-w">
      <Header />
      <Title status={status} requestId={requestId} />
      <Tick status="SUCCESS" text="Payment Created by Merchant" />
      <Tick status="SUCCESS" text="PSU Redirected to bank" />
      <Tick status={status} text="Payment Confirmation Received" />
      <Tick
        status={redirectUrl ? "SUCCESS" : "PENDING"}
        text="Redirect URL Available"
      />

      <Alert
        className="mt-3"
        color={status === "SUCCESS" ? "success" : "danger"}
        isOpen={showAlert}
        toggle={toggleAlert}
      >
        {info}
      </Alert>
      <FooterApi
        status={status}
        loading={loading}
        onClick={makePayment}
        redirect={() => window.open(redirectUrl)}
      />
    </Container>
  );
};

export default GoPaymentCallback;
