import { useState } from "react";
import { Container, Button, Spinner, Alert } from "reactstrap";
import { Header, TitleSimple } from "./components/Structure";
import { confetti, paramsSearch } from "./components/functions";
import { completePaymentCallback } from "../firebase/functions";

const PisCallback = () => {
  const [loading, setLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [status, setStatus] = useState("PENDING");
  const [info, setInfo] = useState(initialInfo);

  const toggleAlert = () => setShowAlert((prev) => !prev);

  async function makePayment() {
    setLoading(true);
    const params = await paramsSearch();
    const [data, error] = await completePaymentCallback(
      params.requestId,
      params.tokenId,
      params.message,
      "pis-callback"
    );

    if (error) {
      setStatus("ERROR");
      setInfo(error.message);
      setLoading(false);
      return;
    }

    setStatus(data.status);
    setInfo(data.info);
    setShowAlert(true);
    setLoading(false);
    if (data.status === "SUCCESS") {
      confetti();
      setDisableBtn(true);
    }
  }
  return (
    <Container className="d-flex flex-column p-6 max-w">
      <Header />
      <TitleSimple status={status} />
      <p>Have a question about this payment?</p>
      <p>
        Email us:{" "}
        <a href="mailto:support@echopay.co.uk">support@echopay.co.uk</a>
      </p>

      <Alert
        className="mt-2"
        color={status === "SUCCESS" ? "success" : "danger"}
        isOpen={showAlert}
        toggle={toggleAlert}
      >
        {info}
      </Alert>
      <div className="fixed-bottom p-3 bg-dark">
        <Button
          onClick={makePayment}
          disabled={disableBtn}
          color="success"
          className="w-100"
        >
          {loading ? <Spinner size="sm" /> : "Complete Payment"}
        </Button>
      </div>
    </Container>
  );
};

const initialInfo = "Please click the button below to complete your payment.";

export default PisCallback;
